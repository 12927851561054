import getGooglePlay from "../assets/getGooglePlay.png";
import betaGooglePlay from "../assets/preregGooglePlay.png";

export default function ButtonPlayStore({ type }) {
  if (type === "preview") {
    return (
      <a
        href="https://bikhair.app/beta"
        target="_blank"
        rel="noopener noreferrer"
        className="inline-block mt-4"
      >
        <img
          src={betaGooglePlay}
          alt="Download Khair on Google Play"
          className="w-40 h-auto rounded-[8px] overflow-hidden"
        />
      </a>
    );
  } else {
    return (
      <a
        href="https://play.google.com/store/apps/details?id=com.bismillahid.mihrabmuslimpanel"
        target="_blank"
        rel="noopener noreferrer"
        className="inline-block mt-4"
      >
        <img
          src={getGooglePlay}
          alt="Download Khair on Google Play"
          className="w-40 h-auto rounded-[8px] overflow-hidden"
        />
      </a>
    );
  }
}
