import heroMockup from "../assets/mockup.png";
import ButtonPlayStore from "./ButtonPlayStore";

export default function Hero() {
  return (
    <section className=" p-12 rounded-[32px] max-w-5xl mx-auto flex flex-col md:flex-row items-center justify-center gap-8 max-h-[420px] mt-8 overflow-visible [contain:layout] sm:h-auto h-svh">
      <div className="absolute bottom-0 left-0 w-full h-32 bg-gradient-to-t from-white to-transparent pointer-events-none"></div>
      <div className="w-full max-w-[428px] text-center md:text-left">
        <h1 className="text-4xl font-bold">
          Ibadah lebih teratur dengan Khair
        </h1>
        <p className="mt-4 text-lg text-gray-500">
          Nggak akan lagi lupa ibadah ✨ Khair siap bantu kamu makin istiqomah
          sesuai sunnah
        </p>
        <ButtonPlayStore type="release" />
      </div>
      <div className="w-full max-w-[250px] flex justify-center overflow-hidden hidden md:block">
        <img
          src={heroMockup}
          alt="Khair"
          className="w-full h-full object-cover "
        />
      </div>
    </section>
  );
}
