import logoInApp from "../assets/logo-in-app.svg";
import listIbadah from "../assets/list-shalat.png";
import listDoa from "../assets/list-doa.png";
import panduanDoa from "../assets/panduan-doa.png";
import subuh from "../assets/item-shalat.png";

import ButtonPlayStore from "../components/ButtonPlayStore";

import Hero from "../components/Hero";

import "../styles/Home.css";

export function Home() {
  return (
    <div className="App">
      <header className="fixed top-0 left-0 w-full h-16 flex items-center justify-center bg-white shadow-sm z-50">
        <img src={logoInApp} alt="Khair" className="w-28" />
      </header>
      <div className="mx-auto w-full max-w-[960px] mb-8 sm:pt-32 pt-12 overflow-hidden">
        <Hero />
      </div>
      <section className="mx-auto w-full max-w-[960px]">
        <div className="columns-1 sm:columns-2 gap-4 space-y-4">
          <div className="bg-gray-50 p-8 h-auto break-inside-avoid rounded-[32px] flex flex-col items-start justify-start text-center gap-4 max-h-[400px] overflow-hidden relative">
            <h2 className="text-2xl w-[320px] self-center">
              Pastikan kamu tak lalai tunaikan salat
            </h2>
            <p className="text-md text-gray-500 min-w-52 w-[320px] self-center mb-2">
              Jadi pemenang dunia & akhirat! Kami bantu kamu jaga salat tepat
              waktu. 🏆
            </p>
            <div className="w-full h-full flex justify-center">
              <img
                src={listIbadah}
                alt="list ibadah"
                className="w-72 h-auto rounded-[24px] object-cover"
              />
            </div>
          </div>
          <div className="bg-gray-50 p-8 pr-0 h-[266px] break-inside-avoid rounded-[32px] flex flex-row items-center justify-start text-left gap-6 max-h-[400px] overflow-hidden relative">
            <div className="flex flex-col justify-center gap-2 min-w-[200px]">
              <h2 className="text-2xl w-full">Sunnah biar makin berkah</h2>
              <p className="text-md text-gray-500 w-full mb-2">
                Upgrade ibadahmu dengan amalan sunnah, pahala naik level! 🚀
              </p>
            </div>
            <div className="flex justify-start start-0">
              <img
                src={listDoa}
                alt="list ibadah"
                className="rounded-[24px] object-cover min-w-[240px]"
              />
            </div>
          </div>
          <div className="bg-gray-50 p-8 h-auto break-inside-avoid rounded-[32px] flex flex-row items-center justify-start text-left gap-8 max-h-[400px] overflow-hidden relative">
            <div className="flex flex-col justify-center gap-2">
              <h2 className="text-2xl w-full">Pelan-pelan tapi pasti</h2>
              <p className="text-md text-gray-500 w-full mb-2">
                Mulai dari ibadah terdekat, jaga terus biar konsisten! 💪
              </p>
            </div>
            <div className="w-72 h-full flex justify-center">
              <img
                src={subuh}
                alt="list ibadah"
                className="w-72 h-auto rounded-[12px] object-cover"
              />
            </div>
          </div>
          <div className="bg-gray-50 p-8 h-auto break-inside-avoid rounded-[32px] flex flex-col items-start justify-start text-center gap-4 max-h-[400px] overflow-hidden relative">
            <h2 className="text-2xl self-center w-[400px]">
              Gass ibadah, tanpa bingung!
            </h2>
            <p className="text-md text-gray-500 min-w-64 w-[320px] self-center mb-2">
              Semua ada panduannya—dari bacaan, tata cara, sampai keutamaannya
              ✨
            </p>
            <div className="w-full h-full flex justify-center">
              <img
                src={panduanDoa}
                alt="list ibadah"
                className="w-120 h-auto rounded-[24px] object-cover"
              />
            </div>
          </div>
        </div>
      </section>
      <div className="mx-auto w-full max-w-[960px] sm:pt-12 overflow-hidden">
        <section className="p-12 rounded-[32px] max-w-5xl mx-auto flex flex-col items-center text-center gap-8 mt-8 overflow-visible [contain:layout]">
          <div className="w-full max-w-[640px]">
            <h1 className="text-4xl font-light italic leading-normal">
              "Barangsiapa hari ini lebih baik daripada hari kemarin, maka ia
              adalah orang yang beruntung”
            </h1>
            <p className="mt-8">✨</p>
            <p className="mt-4 text-lg text-gray-500">
              Ibadah lancar, istiqomah bareng Khair <br />
              Download sekarang!
            </p>
            <ButtonPlayStore type="release" />
          </div>
        </section>
      </div>
      <footer className="w-full max-w-5xl mx-auto flex items-center justify-between py-6 px-4 mt-12 mb-4">
        <p className="text-sm font-semibold">
          <h5>Bismillah ID ® 2025</h5>
        </p>
        <div className="flex items-center space-x-6">
          <a
            href="https://instagram.com/bikhair.app"
            target="_blank"
            rel="noopener noreferrer"
            className="text-sm text-gray-600"
          >
            Instagram
          </a>
          <a href="mailto:admin@bikhair.app" className="text-sm text-gray-600">
            admin@bikhair.app
          </a>
        </div>
      </footer>
    </div>
  );
}
