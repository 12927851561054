import {
  BrowserRouter as Router,
  Routes,
  Route,
  //Link
} from "react-router-dom";
import { Legal } from "./pages/Legal";
import Beta from "./pages/Beta";
import { Home } from "./pages/Home";
import FramerKhair from "./pages/FramerKhair";
import PlayStore from "./pages/PlayStore";

function App() {
  return (
    <div className="App">
      <Router>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/bismillah" element={<FramerKhair />} />
          <Route path="/legal" element={<Legal />} />
          <Route path="/beta" element={<Beta />} />
          <Route path="/download" element={<PlayStore />} />
        </Routes>
      </Router>
    </div>
  );
}

export default App;
